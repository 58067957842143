import { FC } from 'react';
import { Flex, SystemProps, Text, Checkbox, Box } from 'flicket-ui';
import { Icon } from '~components';

interface Props extends SystemProps {
  value?: any;
  onChange?(any): any;
  id?: string;
  label: string;
  icon: string;
}

export const CheckboxPayment: FC<Props> = ({
  value,
  onChange,
  id,
  label,
  icon,
  ...props
}) => {
  if (!onChange) {
    return (
      <Box
        p={'6/4'}
        borderRadius="sm"
        flex="1"
        maxWidth="168px"
        bg="P100"
        border="2px"
        borderColor="P300"
        mr={3}
      >
        <Flex
          variant="center"
          flexDir="column"
          w="100%"
          as="label"
          htmlFor={id}
        >
          <Icon icon={icon as any} fontSize={9} />
          <Text mt={1} variant="label.S" fontWeight="heavy">
            {label}
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Checkbox
      {...props}
      onChange={onChange}
      checked={value}
      id={id}
      p={'6/4'}
      borderRadius="sm"
      flex="1"
      maxWidth="168px"
      bg="P100"
      border="2px"
      borderColor={value ? 'P300' : 'P100'}
      mr={3}
      customLabel={() => (
        <Flex
          ml={'-20px' as any}
          variant="center"
          flexDir="column"
          w="100%"
          as="label"
          htmlFor={id}
        >
          <Icon icon={icon as any} fontSize={9} />
          <Text mt={1} variant="label.S" fontWeight="heavy">
            {label}
          </Text>
        </Flex>
      )}
    />
  );
};
