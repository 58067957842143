export enum PRIMARY_NAVIGATION_KEYS {
  EVENTS = "EVENTS",
  SEASONS = "SEASONS",
  MEMBERSHIPS = "MEMBERSHIPS",
  PACKAGES = "PACKAGES",
  OVERALL = "OVERALL",
}

// name is not used
export const primaryNavigationConfig = {
  [PRIMARY_NAVIGATION_KEYS.EVENTS]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.EVENTS,
    slugSegment: "events",
  },
  [PRIMARY_NAVIGATION_KEYS.SEASONS]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.SEASONS,
    slugSegment: "seasons",
  },
  [PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS,
    slugSegment: "memberships",
  },
  [PRIMARY_NAVIGATION_KEYS.PACKAGES]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.PACKAGES,
    slugSegment: "packages",
  },
  [PRIMARY_NAVIGATION_KEYS.OVERALL]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.OVERALL,
    slugSegment: "overall",
  },
} as const;
