import React, { Fragment, useMemo } from "react";
import { Box, Text, Input } from "flicket-ui";
import { Control, Controller as RHFController } from "react-hook-form";
import useSWR from "swr";
import { Select, Divider, Grid, DatePicker } from "~components";
import { useSDK } from "~hooks";
import { useVenues, useReleases } from "~graphql/hooks";
import { OrderStatus, Role } from "~graphql/sdk";
import { ParamsMethods } from "~features/useParams/hooks/useParams";
import { ExportModalType, ExportModalValues } from "./config";
import { GetGatesByEventIdDocument } from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import { ORDER_TYPES } from "~features/reports/constants";
import { i18n } from "~lib/i18n";

const mapEnumToOptions = (obj: Record<string, string>) =>
  Object.values(obj).map((value) => ({ label: value, value }));

export const scanTypes: Option[] = [
  {
    label: "Tickets",
    value: "tickets",
    isDefault: true,
  },
  {
    label: "Add-ons",
    value: "addons",
  },
];

export const scanTypesWithAll = [
  { label: "All scan types", value: null },
].concat(scanTypes);

type Option = {
  label: string;
  value: any;
  // should only has one default option
  isDefault?: boolean;
};

type OptionStringUnion =
  | "gates"
  | "scanners"
  | "venues"
  | "membershpZones"
  | "membershipTypes"
  | "releases"
  | "eventZones"
  | "ticketTypes"
  | "sellers";

type FilterOptionsWithStringUnion = {
  value: string;
  label: string;
  options?: Option[] | OptionStringUnion;
  type?: "priceRange" | "dateRange" | "multiSelect";
};

type FilterOptions = {
  value: string;
  label: string;
  options?: Option[];
  type?: "priceRange" | "dateRange" | "multiSelect";
};

export const filterOptions: Partial<
  Record<ExportModalType, FilterOptionsWithStringUnion[]>
> = {
  order: [
    {
      value: "status",
      label: "Order status",
      options: [
        ...mapEnumToOptions(OrderStatus),
        { label: "Refunded", value: "refunded" },
        { label: "Partially Refunded", value: "partialRefunded" },
      ],
      type: "multiSelect",
    },
    { value: "amount", label: "Order amount", type: "priceRange" },
    {
      value: "isComp",
      label: "Order type",
      options: ORDER_TYPES,
    },
  ],
  user: [],
  ticket: [],
  membership: [],
  scans: [
    { value: "type", label: "Scan type", options: scanTypes },
    { value: "scanner", label: "Scanner", options: "scanners" },
    { value: "gate", label: "Gate", options: "gates" },
  ],
};

type FiltersProps = {
  type: ExportModalType;
  control: Control<ExportModalValues>;
  paramMethods: ParamsMethods;
  register: any;
};

export const Filters = ({
  type,
  register,
  control,
  paramMethods,
}: FiltersProps) => {
  const sdk = useSDK();

  const { params } = paramMethods;

  const { data: venues } = useVenues();
  const { data: releases } = useReleases(params?.sourceId);

  const { data: membershipTypes } = useSWR(
    type === "membership" && params.sourceId
      ? ["membership", params.sourceId]
      : null,
    async () =>
      sdk
        .membershipTypes({ membership: params.sourceId })
        .then((res) => res.membershipTypes)
  );

  const { data: scanners } = useSWR(
    type === "scans" ? ["users", Role.Scanner] : null,
    async () =>
      sdk
        .users({
          where: {
            roles: [Role.Scanner],
          },
        })
        .then((res) => res.users.edges.map(({ node }) => node))
  );

  const { data: gatesData } = useQuery(
    type === "scans" && params.sourceId && GetGatesByEventIdDocument,
    { eventId: params.sourceId }
  );

  const gates = gatesData?.getGatesByEventId;

  const getDynamicData = (filter: OptionStringUnion) => {
    switch (filter) {
      case "venues":
        return [
          { label: "All venues", value: null },
          ...(venues?.map(({ name, id }) => ({ label: name, value: id })) ||
            []),
        ];

      case "releases":
        return releases?.map(({ name, id }) => ({ label: name, value: id }));

      case "membershipTypes":
        return membershipTypes?.map(({ id, name }) => ({
          label: name,
          value: id,
        }));

      case "scanners":
        return scanners?.map(({ fullName, id }) => ({
          label: fullName,
          value: id,
        }));

      case "gates":
        return gates?.map(({ name, id }) => ({ label: name, value: id }));
    }
  };

  const filters = useMemo(
    () =>
      filterOptions?.[type]?.map((filter) =>
        typeof filter.options === "string"
          ? ({
              ...filter,
              options: getDynamicData(filter.options),
            } as FilterOptions)
          : (filter as FilterOptions)
      ),
    [type, filterOptions, scanners, gates]
  );

  const renderFilter = (filter: Omit<FilterOptions, "label">) => {
    const { value, type: filterType, options } = filter;
    const defaultOption = options?.find((option) => option.isDefault);

    switch (filterType) {
      case "priceRange":
        return (
          <Grid maxWidth={"100%"}>
            <Input
              type="number"
              placeholder="Min"
              name={`filters.${value}.min`}
              ref={register}
            />
            <Input
              type="number"
              placeholder="Max"
              name={`filters.${value}.max`}
              ref={register}
            />
          </Grid>
        );

      case "dateRange":
        return (
          <Box maxWidth={250}>
            <RHFController
              as={DatePicker}
              name={`filters.${value}`}
              control={control}
              options={{ mode: "range" }}
              timezone={i18n.timezone}
              locale={i18n.locale}
            />
          </Box>
        );

      case "multiSelect":
        return (
          <RHFController
            as={Select}
            options={options}
            name={`filters.${value}`}
            control={control}
            maxWidth={"100%"}
            isMulti
          />
        );

      default:
        return (
          <RHFController
            as={Select}
            options={options}
            name={`filters.${value}`}
            control={control}
            maxWidth={"100%"}
            defaultValue={defaultOption?.value}
          />
        );
    }
  };

  return (
    <>
      <Text color="N600" fontSize={4} fontWeight="heavy" mb={1}>
        Filters
      </Text>
      <Divider mt={1} mb={3} />
      <Grid
        gridTemplateColumns="auto 2fr"
        alignItems="center"
        gridColumnGap={72 as any}
      >
        {filters?.map(({ label, ...filter }) => (
          <Fragment key={label}>
            <Text
              fontWeight="extraBold"
              color="N600"
              fontSize={3}
              flexShrink={0}
            >
              {label}
            </Text>

            {renderFilter(filter)}
          </Fragment>
        ))}
      </Grid>
    </>
  );
};
