import { ShopifyProductDto } from "~graphql/typed-document-nodes";
import Select, { CommonProps, components } from "react-select";
import { Plus, X } from "@phosphor-icons/react";
import { FC, JSXElementConstructor, ReactElement, useMemo } from "react";
import { useSearchProducts } from "~forms/settings/integrations/shopify/useSearchProducts";
import { Box, InputWrapper, Stack, Text } from "flicket-ui";
import NextImage from "next/future/image";
import { CustomOptionProps } from "~forms/settings/integrations/shopify/components/ProductSelect";
import styled from "styled-components";
import debounce from "lodash/debounce";

interface MerchandiseItemSelectProps {
  products: ShopifyProductDto[];
  setCurrentValue: (select: SelectOption[]) => void;
  currentValue: SelectOption[];
}
const StyledInputWrapper = styled(InputWrapper)`
  z-index: 1000;
`;

const customStyles = {
  control: (base: any) => ({
    ...base,
    flexDirection: "row-reverse",
  }),
  multiValue: (base) => ({
    ...base,
    display: "none",
  }),
  ///
  clearIndicator: (base: any) => ({
    ...base,
    position: "absolute",
    right: 0,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: "2px 0px",
  }),
};
const DropdownIndicator = (
  props: JSX.IntrinsicAttributes &
    CommonProps<any, boolean> & {
      children: ReactElement<any, string | JSXElementConstructor<any>>;
      innerProps: any;
      isFocused: boolean;
      isRtl: boolean;
      isDisabled: boolean;
    }
) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Plus color={"N800"} />
      </components.DropdownIndicator>
    )
  );
};

export interface SelectOption {
  value: string;
  label: string;
  image: string;
  price?: number;
  url?: string;
}

const SelectItem = ({
  data,
  clearable,
  onClear,
  ...props
}: {
  data: SelectOption;
  clearable?: boolean;
  onClear?: (id: string) => void;
  [key: string]: any;
}) => {
  return (
    <Stack {...props} direction="horizontal" gap={2} alignItems="center">
      <Box
        onClick={() => window.open(data.url, "_blank")}
        width={64}
        height={64}
        backgroundColor="N100"
        overflow="hidden"
        alignItems="center"
        justifyContent="center"
        padding={0}
        margin={0}
        position="relative"
      >
        {data.image ? (
          <NextImage
            src={data.image}
            alt={data.value}
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            fill
            loading="lazy"
          />
        ) : (
          <></>
        )}
      </Box>
      <Stack direction="vertical" gap={1}>
        <Text variant="regular">{data.label}</Text>
        {data.price && <Text variant="small">${data.price}</Text>}
      </Stack>

      {clearable && (
        <Box
          onClick={() => onClear(data.value)}
          alignItems={"end"}
          marginLeft={"auto" as any}
          cursor={"pointer"}
        >
          <X />
        </Box>
      )}
    </Stack>
  );
};
const Option: FC<CustomOptionProps> = ({
  innerProps,
  innerRef,
  data,
  isFocused,
  isSelected,
}) => {
  return (
    <Box
      {...innerProps}
      px={2}
      py={1}
      mb={1}
      mt={1}
      backgroundColor={isSelected ? "P100" : isFocused ? "P100" : "transparent"}
      cursor="pointer"
      ref={innerRef}
    >
      <SelectItem data={data} />
    </Box>
  );
};

export const MerchandiseItemSelect = ({
  products,
  currentValue,
  setCurrentValue,
}: MerchandiseItemSelectProps) => {
  // Keep track of current value for options

  const {
    searchProducts,
    data: productSearchResults,
    isLoading: productsIsLoading,
  } = useSearchProducts();

  const options: ShopifyProductDto[] = useMemo(() => {
    // There are 3 different sources for Product data that need to be merged:
    // 1. The search results from the searchProducts query
    // 2. The Products that are already saved to the event
    // 3. The current new values of the input
    const productData: Record<string, ShopifyProductDto> = {};

    // Search results
    productSearchResults?.searchShopifyProducts?.products?.forEach(
      (product) => {
        productData[product.id] = {
          id: product.id,
          name: product.name,
          imageUrl: product.imageUrl,
          price: product.price,
          url: product.url,
        };
      }
    );

    // Saved Products
    products?.forEach((product) => {
      productData[product.id] = {
        id: product.id,
        name: product.name,
        imageUrl: product.imageUrl,
        price: product.price,
        url: product.url,
      };
    });

    return Object.values(productData);
  }, [productSearchResults, products]);

  const onChange = (selectedOption: any) => {
    setCurrentValue(selectedOption);
  };

  const handleProductSearch = debounce(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      const searchString = (event.target as HTMLInputElement).value;
      await searchProducts(searchString);
    },
    1000
  );

  return (
    <Box>
      {currentValue?.map((product) => (
        <SelectItem
          mt={1}
          mb={1}
          data={product}
          key={product.value}
          clearable={true}
          onClear={(id) => {
            setCurrentValue(currentValue.filter((v) => v.value !== id));
          }}
        />
      ))}
      <StyledInputWrapper>
        <Select
          onFocus={() => searchProducts("")}
          onKeyDown={handleProductSearch}
          value={currentValue}
          placeholder={"Add products"}
          onChange={onChange}
          isLoading={productsIsLoading}
          options={options?.map((o) => ({
            label: o.name,
            value: o.id,
            image: o.imageUrl,
            price: o.price,
            url: o.url,
          }))}
          isClearable={false}
          isMulti={true}
          styles={customStyles}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
        />
      </StyledInputWrapper>
    </Box>
  );
};
