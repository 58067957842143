import styled from "styled-components";
import { Checkbox as FlicketCheckbox } from "flicket-ui";

const StyledFlicketCheckbox = styled(FlicketCheckbox)`
  + label {
    font-weight: normal;
    margin-left: 12px;
  }
`;

export default function Checkbox(props) {
  return <StyledFlicketCheckbox {...props} />;
}
