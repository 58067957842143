import { CaretLeft, X } from "@phosphor-icons/react";
import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from "@reach/dialog";
import { pick } from "@styled-system/props";
import { Box, Flex, system, SystemProps, Text } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { noop } from "lodash";
import { ReactNode, useContext } from "react";
import styled, { css } from "styled-components";
import HoverIcon from "~features/generalAdmissionEvent/components/common/HoverIcon/HoverIcon";
import {
  modalBackdropMotion,
  modalContentMotion,
} from "~lib/helpers/animation/modal";
import { ModalContext } from "./useCustomModal";

const MOBILE_BREAKPOINT = "sm";

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDialog = motion(ReachDialogContent);

const DialogOverlay = styled(MotionOverlay)<{
  $alignModalTop: boolean;
}>`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: ${(p) => p.theme.zIndices.modal};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    align-items: ${(p) => (p.$alignModalTop ? " flex-start" : "center")};
    padding: ${(p) => p.theme.space[4]}px;
  }

  && {
    ${system}
  }
`;

const DialogContent = styled(MotionDialog)<SystemProps>`
  padding: 0;
  width: 100%;
  margin: 0;
  position: relative;
  background: transparent;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    width: 774px;
  }

  && {
    ${system}
  }
`;

const ContentWrapper = styled.div<SystemProps>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background: ${(p) => p.theme.colors.white};
  outline: none;
  width: 100%;
  height: 100dvh;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    border-radius: ${(p) => p.theme.radii.sm};
    box-shadow: ${(p) => p.theme.shadows.sm};
    height: auto;
    max-height: 92dvh;
  }

  && {
    ${system}
  }
`;

type ModalProps = SystemProps<{
  isOpen: boolean;
  close: (any) => void;
  ariaLabel?: string;
  children: ReactNode;
  hasDivider?: boolean;
  small?: boolean;
  alignModalTop?: boolean;
  dialogContentProps?: SystemProps;
  modalBaseProps?: SystemProps;
  clickOutsideToClose?: boolean;
  dialogOverlayProps?: SystemProps;
}>;

export const ModalBaseWrapper = ({
  children,
  ariaLabel = "modal",
  isOpen,
  close,
  small = false,
  alignModalTop = false,
  dialogContentProps = {},
  dialogOverlayProps = {},
  ...props
}: ModalProps) => (
  <AnimatePresence>
    {isOpen && (
      <DialogOverlay
        {...modalBackdropMotion}
        onDismiss={close}
        $alignModalTop={alignModalTop}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        {...pick(dialogOverlayProps)}
      >
        <DialogContent
          {...modalContentMotion}
          aria-label={ariaLabel}
          width={small ? "470px" : undefined}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          {...pick(dialogContentProps)}
        >
          <ContentWrapper
            id="modal-content-wrapper"
            $small={small}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            {...pick(props)}
          >
            {children}
          </ContentWrapper>
        </DialogContent>
      </DialogOverlay>
    )}
  </AnimatePresence>
);

const CustomModal = ({
  children,
  ariaLabel,
  isOpen,
  close,
  clickOutsideToClose = false,
  small,
  ...props
}: ModalProps) => (
  <ModalContext.Provider value={{ close, small }}>
    <ModalBaseWrapper
      close={clickOutsideToClose ? close : noop}
      isOpen={isOpen}
      ariaLabel={ariaLabel}
      small={small}
      {...props}
    >
      {children}
    </ModalBaseWrapper>
  </ModalContext.Provider>
);

function Header({
  children,
  onBack,
}: {
  children: ReactNode;
  onBack?: () => void;
  close?: () => void;
}) {
  const { close, small } = useContext(ModalContext);
  return (
    <Box
      pt={"20px" as any} // custom pt to account for font height
      pb={2}
      pr={8}
      pl={onBack ? 8 : small ? [3] : [3, 3, 4]}
      d="flex"
      position="relative"
      alignItems="center"
      justifyContent="space-between"
      borderBottomColor="N200"
      borderBottomStyle="solid"
      css={css({
        // Don't know why this property is not working with typescript on the component 🤷
        borderBottomWidth: "1px",
        lineHeight: 1,
      })}
    >
      {onBack && (
        <HoverIcon
          icon={<CaretLeft size={20} weight="bold" />}
          onClick={onBack}
          w="40px"
          h="40px"
          position="absolute"
          left="19px"
          color="N800"
        />
      )}

      {children && (
        <Text
          as="h5"
          variant="header.S"
          width={1}
          textAlign={onBack ? "center" : "left"}
        >
          {children}
        </Text>
      )}

      <HoverIcon
        icon={<X size={20} />}
        onClick={close}
        w="40px"
        h="40px"
        position="absolute"
        right="19px"
        color="N800"
      />
    </Box>
  );
}

function Content({
  children,
  ...props
}: { children: ReactNode; small?: boolean } & SystemProps) {
  const { small } = useContext(ModalContext);

  return (
    <Box
      fontSize={4}
      overflowY="auto"
      flex={1}
      py={small ? 2 : 3}
      paddingLeft={small ? [3] : [3, 3, 4]}
      paddingRight={small ? [3] : [3, 3, 4]}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      {children}
    </Box>
  );
}

// eslint-disable-next-line react/display-name
function Footer({ children, ...props }: { children: ReactNode } & SystemProps) {
  const { small } = useContext(ModalContext);

  return (
    <Box
      py={2}
      px={small ? [2] : [3, 3, 4]}
      zIndex={1}
      borderTopStyle="solid"
      borderTopColor="N200"
      css={css({
        // Don't know why this property is not working with typescript on the component 🤷
        borderTopWidth: "1px",
      })}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      {...pick(props)}
    >
      <Flex justifyContent="flex-end">{children}</Flex>
    </Box>
  );
}

CustomModal.Footer = Footer;
CustomModal.Header = Header;
CustomModal.Content = Content;

export default CustomModal;
