import { ReactElement, ReactNode, cloneElement, useEffect } from "react";
import { Box, Flex, Text, InputWrapper, SystemProps } from "flicket-ui";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Checkbox from "~features/generalAdmissionEvent/components/Checkbox";
import { SectionTitle } from "../FormSectionHelpers";
import styled from "styled-components";
import { pick } from "@styled-system/props";
import { Plus } from "@phosphor-icons/react";
import { Input } from "~components/common/Input";
import { Select } from "~components/common/Select";
import { Icon } from "~components/common/Icon";
import { extractErrorMessageFromFormName } from "~lib/helpers/form/extractErrorMessageFromFormName";

interface CallbackProps {
  isVisible: boolean;
  setVisibility: (visible: boolean) => void;
  onToggle: (isVisible: boolean) => void;
}

type CallbackFunc = (props: CallbackProps) => ReactElement;

export function FormPartialCollapse({
  name,
  children,
  onToggle,
  defaultValue = false,
}: {
  name: string;
  children: CallbackFunc;
  onToggle?: CallbackProps["onToggle"];
  defaultValue?: boolean;
}) {
  const { control, register } = useFormContext();

  const isVisible = Boolean(
    useWatch({
      name,
      control,
      defaultValue: (defaultValue ||
        control.getValues(name) ||
        false) as boolean,
    })
  );

  function setVisibility(isVisible: boolean) {
    control.setValue(name, isVisible, { shouldDirty: true });
    onToggle?.(isVisible);
  }

  useEffect(() => {
    // We need to register the field because it might be initially hidden
    register(name);
  }, []);

  return <Box mb={2}>{children({ isVisible, setVisibility, onToggle })}</Box>;
}

export const StyledCheckbox = styled(Checkbox)<{ $alignTop?: boolean }>`
  ${(p) =>
    p.$alignTop &&
    `
      position: relative;
      top: -10px;
  `};
`;

export function FormPartialCheckbox({
  name,
  label,
  children,
  description,
  onToggle,
  defaultValue,
  customLabel,
  disabled,
  ...props
}: {
  name: string;
  label: ReactNode;
  description?: ReactNode;
  children?: ReactElement | CallbackFunc;
  onToggle?: CallbackProps["onToggle"];
  disabled?: boolean;
  defaultValue?: boolean;
  customLabel?: () => ReactNode;
} & SystemProps) {
  const { formState, trigger } = useFormContext();
  const { isSubmitted } = formState;

  return (
    <Box mb={2} {...pick(props)}>
      <FormPartialCollapse name={name} defaultValue={defaultValue}>
        {({ isVisible, setVisibility }) => {
          const callbackProps: CallbackProps = {
            setVisibility,
            isVisible,
            onToggle,
          };

          const showChildren = children && isVisible;

          return (
            <>
              <Flex flexDir="row" mb={2}>
                <StyledCheckbox
                  $alignTop={!!description}
                  id={label}
                  name={name}
                  disabled={disabled}
                  onChange={() => {
                    setVisibility(!isVisible);

                    // Revalidate the form if it's invalid.
                    // This is to not show the error message if the user
                    // disables the checkbox option that has validation errors
                    if (isSubmitted) {
                      void trigger();
                    }
                  }}
                  checked={isVisible}
                  customLabel={
                    customLabel ??
                    (() => (
                      // @ts-expect-error styled-system doesn't recognize the htmlFor prop
                      <Text as="label" htmlFor={label}>
                        <Text fontWeight="extraBold" fontSize={3}>
                          <Flex alignItems="center">{label}</Flex>
                        </Text>
                        {description && (
                          <Text fontWeight="regular" fontSize={2}>
                            {description}
                          </Text>
                        )}
                      </Text>
                    ))
                  }
                />
              </Flex>

              {showChildren && (
                <Box pl={[0, 0, 4]} mb={3}>
                  {typeof children === "function"
                    ? children(callbackProps)
                    : cloneElement(children, callbackProps)}
                </Box>
              )}
            </>
          );
        }}
      </FormPartialCollapse>
    </Box>
  );
}

export function FormPartialSectionTitleCollapse({
  name,
  title,
  children,
  smallTitle = false,
  defaultValue,
}: {
  name: string;
  title: ReactNode;
  children?: ReactNode;
  defaultValue?: boolean;
  smallTitle?: boolean;
}) {
  return (
    <Box mb={2}>
      <FormPartialCollapse name={name} defaultValue={defaultValue}>
        {({ isVisible, setVisibility }) => {
          return (
            <>
              {!isVisible && (
                <Flex
                  display="inline-flex"
                  color="P300"
                  cursor="pointer"
                  alignItems="center"
                  mb={2}
                  onClick={() => setVisibility(true)}
                >
                  <Icon icon={<Plus weight="regular" size={16} />} mr={"1/4"} />
                  <Text
                    textDecoration="underline"
                    variant="regular"
                    color="P300"
                  >
                    {title}
                  </Text>
                </Flex>
              )}

              {isVisible && (
                <Box mb={4} pt={1}>
                  <SectionTitle
                    title={title}
                    small={smallTitle}
                    onClick={() => setVisibility(false)}
                    actionLabel="Delete"
                  />
                  {children}
                </Box>
              )}
            </>
          );
        }}
      </FormPartialCollapse>
    </Box>
  );
}

const StyledSelect = styled(Select)`
  .react-select__control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    box-shadow: none;
    background: ${(p) => p.theme.colors.N100};
    transition: color 0s;
  }

  .react-select__value-container {
    padding: 8px 0px 8px 12px;
    position: absolute;
  }

  .react-select__single-value {
    font-size: 18px;
    font-weight: 400;
    color: ${(p) => p.theme.colors.N600};
  }

  .react-select__indicators {
    padding-left: 20px;

    .react-select__dropdown-indicator svg {
      color: ${(p) => p.theme.colors.N500};
    }
  }
`;

const StyledInput = styled(Input)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
`;

const StyledInputWrapper = styled(InputWrapper)<{ $isValid: boolean }>`
  // Enable focus states for both the input and the select
  :focus-within {
    input,
    .react-select__control {
      border-color: ${(p) => p.theme.colors[!p.$isValid ? "error" : "N500"]};
    }
  }

  //set hover on the select to same as border or error when hovered, essentiall
  // cancelling it out.
  &:hover:not(:focus-within) {
    .react-select__control {
      border-color: ${(p) => p.theme.colors[!p.$isValid ? "error" : "N200"]};
    }
  }
`;

export function FormPartialInputWithSelect({
  options,
  selectName,
  inputName,
  label,
  placeholder,
  ...rest
}: {
  options: {
    label: string;
    value: string;
  }[];
  selectName: string;
  inputName: string;
  label: ReactNode;
  placeholder?: string;
}) {
  const { control, errors } = useFormContext();
  const errorMessage = extractErrorMessageFromFormName(inputName, errors);

  return (
    <StyledInputWrapper label={label} name={inputName} $isValid={!errorMessage}>
      <Flex>
        <Flex>
          <Controller
            as={StyledSelect}
            control={control}
            options={options}
            name={selectName}
            mr={0}
            width={56}
            isSearchable={false}
            error={errorMessage}
            hideErrorMessage={true}
            menuPortalTarget={document.body}
          />
        </Flex>
        <Flex zIndex={1} width="100%">
          <Controller
            name={inputName}
            as={StyledInput}
            prefix={null}
            type="number"
            placeholder={placeholder}
            error={errorMessage}
            hideErrorMessage={true}
            {...rest}
          />
        </Flex>
      </Flex>
      {errorMessage && (
        <Text fontSize={2} mt={1} color="error">
          {errorMessage}
        </Text>
      )}
    </StyledInputWrapper>
  );
}
