import { createContext } from "react";
import { SourceSearchIndexQuery } from "~graphql/sdk";

export type SourceContext = {
  reportingSearchIndex: {
    searchableEvents: SourceSearchIndexQuery["reportingSearchIndex"]["searchableEvents"];
    searchableMemberships: SourceSearchIndexQuery["reportingSearchIndex"]["searchableMemberships"];
  };
};
export const SourceControlContext = createContext<SourceContext>(null);
