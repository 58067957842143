import { useRouter } from "next/router";
import { createContext, ReactNode, useContext } from "react";
import {
  EventDocument,
  MembershipDocument,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import { getErrorMessage } from "~lib";

export type EmailDataContextType = "event" | "membership";

type EmailDataContext = {
  type: EmailDataContextType;
  id?: string;
  name?: string;
  isLoading?: boolean;
  errorMessage?: string;
  importantInfo?: string;
};

export const EmailDataContext = createContext<EmailDataContext>(null);

export default function useEmailDataContext(): EmailDataContext {
  const context = useContext(EmailDataContext);

  return context;
}

export const EmailDataContextProvider = ({
  children,
  type,
}: {
  children: ReactNode;
  type: EmailDataContextType;
}) => {
  const router = useRouter();
  const id = router.query.id as string;

  const {
    data: event,
    isLoading: isEventLoading,
    error: eventError,
  } = useQuery(type === "event" && EventDocument, {
    id,
  });

  const {
    data: membership,
    isLoading: isMembershipLoading,
    error: membershipError,
  } = useQuery(type === "membership" && MembershipDocument, {
    id,
  });

  let contextValue: EmailDataContext;

  if (type === "event") {
    contextValue = {
      type: "event",
      id,
      name: event?.event?.title,
      isLoading: isEventLoading,
      errorMessage: eventError ? getErrorMessage(eventError) : undefined,
      importantInfo: event?.event?.importantNotice,
    };
  } else if (type === "membership") {
    contextValue = {
      type: "membership",
      id,
      name: membership?.membership?.name,
      isLoading: isMembershipLoading,
      errorMessage: membershipError
        ? getErrorMessage(membershipError)
        : undefined,
      importantInfo: membership?.membership?.importantNotice,
    };
  }

  return (
    <EmailDataContext.Provider value={contextValue}>
      {children}
    </EmailDataContext.Provider>
  );
};
