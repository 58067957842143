import React, { Dispatch, FC, SetStateAction } from "react";
import { yupResolver } from "@hookform/resolvers";
import { Box, Input, Flex, Text, PrimaryButton, Checkbox } from "flicket-ui";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { Select, Status } from "~components";
import { useSDK, useUser } from "~hooks";
import {
  GetHistoryByCustomerIdQuery,
  HistoryReferenceType,
} from "~graphql/sdk";
import { showToast, getError } from "~lib/helpers";
import { Avatar } from "./Avatar";
import { mutate } from "swr";
import { HistoryItem } from "./HistoryItem";

const schema = yup.object().shape({
  description: yup.string().required(),
});

export const getHistorySelectRefType = (isEventManager = false) => [
  { label: "User", value: "user" },
  ...(!isEventManager ? [{ label: "Scout", value: "scout-user" }] : []),
  { label: "Order", value: "order" },
  { label: "Scan", value: "scan" },
];

interface Props {
  refName: HistoryReferenceType;
  refId: string;
  history: GetHistoryByCustomerIdQuery["getHistoryByCustomerId"];
  error: Error;
  select: string[];
  setSelect: Dispatch<SetStateAction<string[]>>;
  userId?: string;
}

type FormInputs = {
  description: string;
};

export const History: FC<Props> = ({
  refName,
  refId,
  history,
  error,
  select,
  setSelect,
  userId,
}) => {
  const sdk = useSDK();
  const [condensed, setCondensed] = React.useState(false);
  const { user } = useUser();
  const { isEventManager } = useUser();

  const {
    control,
    handleSubmit,
    watch,
    formState,
    reset,
  } = useForm<FormInputs>({
    defaultValues: { description: "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (val: FormInputs) => {
    try {
      await sdk.createHistory({
        input: { refId, refName, description: val.description },
      });
      await mutate(["getHistoryByCustomerId", userId, select]);
      showToast("History updated", "success");
      reset({ description: "" });
    } catch (err) {
      showToast(getError(err, "graphQL"), "error");
    }
  };

  return (
    <>
      <Flex
        alignItems="flex-start"
        mb={4}
        as="form"
        id="history"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <Avatar user={user} />
        <Box mr={1} flex={1}>
          <Controller
            as={Input}
            control={control}
            name="description"
            autoComplete="off"
          />
          <Text color="N400" fontSize={3}>
            Only you and other administrators can see notes
          </Text>
        </Box>
        <PrimaryButton
          type="button"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(onSubmit)}
          disabled={!watch("description") || formState.isSubmitting}
          isLoading={formState.isSubmitting}
        >
          Post
        </PrimaryButton>
      </Flex>

      <Flex flexDirection="column" flex={1}>
        <Status loading={!error && !history} error={error}>
          <>
            <Select
              label="Filter"
              options={getHistorySelectRefType(isEventManager)}
              defaultvalue={select}
              onChange={(source: HistoryReferenceType[]) => {
                setSelect(source);
              }}
              value={select}
              mb={2}
              isMulti
            />
            <Checkbox
              label="Condensed view"
              checked={condensed}
              onChange={() => setCondensed(!condensed)}
            />
            {history?.map((d) => (
              <HistoryItem item={d} key={d.id} condensed={condensed} />
            ))}
          </>
        </Status>
      </Flex>
    </>
  );
};
