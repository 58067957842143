import { Box, Checkbox, Text } from "flicket-ui";
import { useEffect, useState } from "react";
import { Icon } from "~components";
import { CaretRight, Flag } from "@phosphor-icons/react";
import styled, { css } from "styled-components";
import { useOrganization } from "~hooks";
import useSessionFeatureFlags, {
  SessionFeatureFlagType,
  FeatureFlag,
} from "~hooks/useSessionFeatureFlags";
import { OrganizationFeatures } from "~lib/features";
import { useRouter } from "next/router";

const ItemCheckbox = styled(Checkbox)`
  && {
    &:checked {
      border-color: black;
    }

    &:before {
      background-color: black;
    }
  }
`;

export default function FeatureFlagAdminPanel() {
  const { organization } = useOrganization();

  const {
    featureFlags,
    addFeatureFlags,
    toggleFeatureFlag,
  } = useSessionFeatureFlags();
  const [isOpen, setIsOpen] = useState(false);

  function togglePanelOpen() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    // When the organization loads, store all feature flags into the session storage
    if (organization) {
      const features: SessionFeatureFlagType = Object.values(
        OrganizationFeatures
      ).reduce((acc, feature) => {
        acc[feature] =
          featureFlags[feature] ?? organization.features?.includes(feature);
        return acc;
      }, {});

      addFeatureFlags(features);
    }
  }, [organization]);

  const router = useRouter();

  const hideOnPathnameMatch = ["/reports/event/"];
  const shouldHide = hideOnPathnameMatch.some((value) => {
    return router.pathname.includes(value);
  });

  if (shouldHide) {
    return null;
  }

  return (
    <>
      <Box
        css={css((p) => ({
          position: "fixed",
          right: isOpen ? 0 : -280,
          top: 0,
          width: 280,
          bottom: 0,
          backgroundColor: p.theme.colors.N200,
          zIndex: 999999,
          boxShadow: p.theme.shadows.hover,
        }))}
      >
        <Box
          css={css((p) => ({
            position: "absolute",
            left: -30,
            top: 0,
            width: 30,
            height: 30,
            cursor: "pointer",
            backgroundColor: p.theme.colors.N200,
          }))}
          onClick={togglePanelOpen}
        >
          <Icon
            icon={
              isOpen ? (
                <CaretRight size={30} weight="regular" />
              ) : (
                <Flag size={30} weight="duotone" />
              )
            }
          />
        </Box>{" "}
        <Box mb={2} overflowY="auto" pb={8} height="100%">
          <Box p={2}>
            <Text variant="header.S">Feature Flags</Text>
            <Text variant="regular" mb={2}>
              These changes only effect your current browser tab session. You
              may need to refresh the page if you do not see changes.
            </Text>
          </Box>
          <ul>
            {Object.entries(featureFlags).map(([feature, isChecked]) => {
              return (
                <li
                  key={feature}
                  css={css({ listStyle: "none", paddingLeft: 16 })}
                >
                  <ItemCheckbox
                    label={feature}
                    checked={isChecked}
                    onChange={() =>
                      toggleFeatureFlag(feature as FeatureFlag, !isChecked)
                    }
                  />
                </li>
              );
            })}
          </ul>
        </Box>
      </Box>
    </>
  );
}
