import { useSlate } from "slate-react";
import { BlockButton, MarkButton } from "./Buttons";
import { GroupInsertOptions, RichtextEditorSelect } from "./Dropdown";
import { EditImageButton } from "./Image";
import { LinkButton } from "./Link";
import {
  Button,
  ButtonDivider,
  Toolbar as ToolbarComponent,
} from "./components";
import { selectedBannerImage, selectedImage, selectedVideo } from "./util";
import { SystemProps, Text } from "flicket-ui";
import {
  AlignCenterHorizontalSimple,
  AlignLeftSimple,
  AlignRightSimple,
  IconContext,
  ListBullets,
  ListNumbers,
  Palette,
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
  TextB,
  TextH,
  TextHOne,
  TextHThree,
  TextHTwo,
  TextItalic,
} from "@phosphor-icons/react";
import { ToolbarOption } from ".";
import { ReactNode, useState } from "react";
import RichTextStylesModal from "./richTextStyles/components/RichTextStylesModal";
import { Icon } from "../Icon";
import { MediaDeleteButton } from "./video";
import {
  RichTextStyles,
  StylesChangeFn,
} from "./richTextStyles/richTextStyleTypes";

interface ToolbarProps {
  selectEvents: boolean;
  insertDropdownOptions: GroupInsertOptions[];
  toolbarProps?: SystemProps;
  toolbarOptions: ToolbarOption[];
  toolbarImageOptions?: ToolbarOption[];
  toolbarVideoOptions?: ToolbarOption[];
  toolbarBannerOptions?: ToolbarOption[];
  richTextStyle: RichTextStyles;
  onRichTextStyleChange?: StylesChangeFn;
}

export default function Toolbar(props: ToolbarProps) {
  const {
    selectEvents,
    insertDropdownOptions,
    toolbarOptions = [],
    toolbarImageOptions = [],
    toolbarVideoOptions = [],
    toolbarBannerOptions,
    richTextStyle,
    onRichTextStyleChange,
  } = props;

  const editor = useSlate();
  const video = selectedVideo(editor);
  const image = selectedImage(editor);
  const banner = selectedBannerImage(editor);

  const optionsIterator = banner
    ? toolbarBannerOptions
    : image
    ? toolbarImageOptions
    : video
    ? toolbarVideoOptions
    : toolbarOptions;

  const toolbar: ReactNode[] = [];

  // The below toolbar elements are ordered according to the
  // supplied toolbarOptions array.
  optionsIterator.forEach((option, index) => {
    switch (option) {
      case "divider":
        toolbar.push(<ButtonDivider key={`toolbar-${option}-${index}`} />);
        break;
      case "text-left":
        toolbar.push(
          <BlockButton
            format="left"
            icon={<TextAlignLeft />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "text-center":
        toolbar.push(
          <BlockButton
            format="center"
            icon={<TextAlignCenter />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "text-right":
        toolbar.push(
          <BlockButton
            format="right"
            icon={<TextAlignRight />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;

      case "bold":
        toolbar.push(
          <MarkButton
            format="bold"
            icon={<TextB />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "italic":
        toolbar.push(
          <MarkButton
            format="italic"
            icon={<TextItalic />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "heading-one":
        toolbar.push(
          <BlockButton
            format="heading-one"
            icon={<TextHOne />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "heading-two":
        toolbar.push(
          <BlockButton
            format="heading-two"
            icon={<TextHTwo />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "heading-three":
        toolbar.push(
          <BlockButton
            format="heading-three"
            icon={<TextHThree />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "heading":
        toolbar.push(
          <BlockButton
            format="heading-three"
            icon={<TextH />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "numbered-list":
        toolbar.push(
          <BlockButton
            format="numbered-list"
            icon={<ListNumbers />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "bulleted-list":
        toolbar.push(
          <BlockButton
            format="bulleted-list"
            icon={<ListBullets />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "link":
        toolbar.push(<LinkButton key={`toolbar-${option}-${index}`} />);
        break;
      case "styles":
        toolbar.push(
          <StylesButton
            onChange={onRichTextStyleChange}
            richTextStyle={richTextStyle}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "insert":
        insertDropdownOptions?.length > 0 &&
          toolbar.push(
            <RichtextEditorSelect
              selectEvents={selectEvents}
              insertOptions={insertDropdownOptions}
              key={`toolbar-${option}-${index}`}
            />
          );
        break;

      // Media related
      case "align-left":
        toolbar.push(
          <BlockButton
            format="left"
            icon={<AlignLeftSimple />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "align-center":
        toolbar.push(
          <BlockButton
            format="center"
            icon={
              <AlignCenterHorizontalSimple key={`toolbar-${option}-${index}`} />
            }
          />
        );
        break;
      case "align-right":
        toolbar.push(
          <BlockButton
            format="right"
            icon={<AlignRightSimple />}
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "media-small":
        toolbar.push(
          <BlockButton
            format="small"
            text="Small"
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "media-medium":
        toolbar.push(
          <BlockButton
            format="medium"
            text="Medium"
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "media-large":
        toolbar.push(
          <BlockButton
            format="large"
            text="Large"
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "media-full":
        toolbar.push(
          <BlockButton
            format="full"
            text="Full"
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "image-edit":
        (image ?? banner) &&
          toolbar.push(
            <EditImageButton
              image={image ?? banner}
              isBanner={!!banner}
              key={`toolbar-${option}-${index}`}
            />
          );
        break;
      case "image-delete":
        toolbar.push(
          <MediaDeleteButton
            label="Delete image"
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
      case "video-delete":
        toolbar.push(
          <MediaDeleteButton
            label="Delete video"
            key={`toolbar-${option}-${index}`}
          />
        );
        break;
    }
  });

  return (
    <IconContext.Provider
      value={{
        size: 20,
        weight: "regular",
      }}
    >
      <ToolbarComponent
        flexWrap="wrap"
        px={1}
        py={"1/2"}
        minHeight="46px"
        {...props.toolbarProps}
      >
        {toolbar}
      </ToolbarComponent>
    </IconContext.Provider>
  );
}

function StylesButton({
  onChange,
  richTextStyle,
}: {
  onChange: StylesChangeFn;
  richTextStyle: RichTextStyles;
}) {
  const [stylesModalOpen, setStylesModalOpen] = useState(false);

  return (
    <>
      <Button h="30px" px={1} onClick={() => setStylesModalOpen(true)}>
        <Icon icon={<Palette />} mr="1/2" />
        <Text variant="regular" color="N800">
          Styles
        </Text>
      </Button>
      <RichTextStylesModal
        isOpen={stylesModalOpen}
        onClose={() => {
          setStylesModalOpen(false);
        }}
        richTextStyle={richTextStyle}
        onChange={onChange}
      />
    </>
  );
}
