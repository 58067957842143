import { Box, Text } from "flicket-ui";
import Select, { GroupType, OptionProps, components } from "react-select";
import { useTheme } from "styled-components";
import useLayoutContext from "../../../hooks/useLayoutContext";
import { EventOptionType, allEvents, groupEvents, isAllEvents } from "../util";
import {
  CommonDisplay,
  defaultComponents,
  renderGroup,
  selectStyles,
} from "./common";
import { EventSearchItem, SearchItem } from "../SearchItem";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { Skeleton } from "~components";
import { formatEventDateRange } from "~lib/helpers/dates/formatDateRange";
import useNavigation from "~features/reports/reporting/navigation/useNavigation";

interface EventSelectProps {
  onChange: (option: EventOptionType) => void;
}

export const Option = (props: OptionProps<EventOptionType, false>) => {
  return (
    <components.Option {...props}>
      {isAllEvents(props.data) ? (
        <SearchItem active={props.isFocused}>All events</SearchItem>
      ) : (
        <EventSearchItem searchEvent={props.data} active={props.isFocused} />
      )}
    </components.Option>
  );
};

function Subtitle() {
  const context = useLayoutContext();

  if (context.pageState === "layoutLoading") {
    return <Skeleton height={15} maxWidth={"400px"} mt={"6/4"} />;
  }

  if (
    context.type !== PRIMARY_NAVIGATION_KEYS.EVENTS ||
    !context.eventInformation
  )
    return null;

  const eventDate = formatEventDateRange(context.eventInformation.dates);

  return (
    <Box mt={1}>
      {eventDate && <Text variant="small">{eventDate}</Text>}
      {context.eventInformation?.location && (
        <Text variant="small">{context.eventInformation?.location}</Text>
      )}
    </Box>
  );
}

export function EventDisplay() {
  const context = useLayoutContext();

  if (context.type !== PRIMARY_NAVIGATION_KEYS.EVENTS) {
    return null;
  }

  let image = undefined;
  let title;

  if (context.isAllEvents) {
    image = null;
    title = allEvents.label;
  } else {
    image = context.eventInformation?.logo ?? undefined;

    if (context.isDefaultEvent) {
      title = context.eventInformation?.title;
    } else {
      const eventId = context.eventId;
      title =
        context.eventInformation?.title ??
        context.searchableEvents.find((se) => se.id === eventId)?.title;
    }
  }

  return (
    <CommonDisplay
      image={image}
      title={title}
      subtitle={<Subtitle />}
      sourceLabel={context.type}
    />
  );
}

export default function EventSelect(props: EventSelectProps) {
  const theme = useTheme();
  const context = useLayoutContext();
  const { setSelectedSource } = useNavigation();
  const { searchableEvents } = context;

  const options: (EventOptionType | GroupType<EventOptionType>)[] = [];

  options.push(allEvents);
  options.push(...groupEvents(searchableEvents));

  const onChange = (option: EventOptionType) => {
    if (isAllEvents(option)) {
      setSelectedSource("all-events");
    } else {
      setSelectedSource({ eventId: option.id });
    }

    props.onChange(option);
  };

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: EventOptionType) => {
        return option.id;
      }}
      getOptionLabel={(option: EventOptionType) => {
        return isAllEvents(option) ? option.label : option.title;
      }}
      formatGroupLabel={renderGroup}
      onChange={onChange}
      components={{ ...defaultComponents, Option }}
      styles={selectStyles(theme)}
      placeholder="Search events"
    />
  );
}
