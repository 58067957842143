import React, { CSSProperties, FC } from "react";
import { Box, Flex, Spinner, Text } from "flicket-ui";
import Link from "next/link";

import { Icon, Loader } from "~components";
import { getError } from "~lib/helpers";
import variant from "src/lib/variant";

interface StatusProps {
  loading: boolean;
  error: any;
  logoAsLoader?: boolean;
  backTo?: {
    label: string;
    route: string;
    as?: string;
  };
  showBackButton?: boolean;
  loadingStyle?: CSSProperties;
  loadingMessage?: string;
}

export const Status: FC<StatusProps> = ({
  children,
  loading,
  logoAsLoader = false,
  error,
  showBackButton = false,
  backTo,
  loadingStyle = {},
  loadingMessage,
}) => {
  if (loading) {
    return (
      <Flex
        variant="center"
        flex="1"
        p={"40px" as any}
        flexDirection="column"
        w="100%"
        style={loadingStyle}
      >
        {logoAsLoader ? <Loader /> : <Spinner size={48} color="P300" />}
        {loadingMessage && (
          <Text mt={3} variant="regular">
            {loadingMessage}
          </Text>
        )}
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex
        variant="center"
        flex="1"
        p={"40px" as any}
        flexDirection="column"
        w="100%"
      >
        <Text variant="title.M">Error</Text>
        <Text variant="title.XS">{getError(error, "graphQL")}</Text>
        {showBackButton && (
          <Box mt={2}>
            <Link
              {...(backTo
                ? { href: backTo.route, as: backTo.as }
                : { href: "/" })}
              passHref
            >
              <Text
                color="N400"
                variant="extraBold.L"
                as="a"
                alignItems="center"
                display="flex"
              >
                <Icon icon="chevron-left" mr={1} fontSize={6} />
                {backTo?.label || "Back to Home"}
              </Text>
            </Link>
          </Box>
        )}
      </Flex>
    );
  }

  return <>{children}</>;
};
