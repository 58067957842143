import { Box } from "flicket-ui";
import { ReactNode } from "react";
import { RenderElementProps } from "slate-react";
import { ColumnElement } from "./interface/slate.interface";

type ColumnProps = Pick<RenderElementProps, "attributes"> & {
  children: ReactNode;
  element: ColumnElement;
};

export default function SlateColumn(props: ColumnProps) {
  const { children, element } = props;

  let padding = 0;
  let border = "none";

  if (element.variant === "border-box") {
    padding = 4;
    border = "1px solid black";
  }

  return (
    <Box
      width={element.width}
      p={padding as any}
      border={border}
      {...props.attributes}
    >
      {children}
    </Box>
  );
}
