import { CaretRight } from "@phosphor-icons/react";
import { Box, Stack, SystemProps } from "flicket-ui";
import { motion } from "framer-motion";
import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "../Icon";

interface HeaderProps extends Pick<CollapsibleProps, "title"> {
  open: boolean;
  setOpen: (value: boolean) => void;
}

interface CollapsibleProps extends SystemProps {
  title?: string | ReactElement;
  active?: boolean;
  header?: (headerProps: HeaderProps) => React.ReactNode;
  children: React.ReactNode;
}

const StyledIcon = styled(Icon)`
  --focus-color: ${(p) => p.theme.colors.N800};
  border-radius: ${(p) => p.theme.radii.md};
  min-height: 0;
  height: 100%;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: transparent;
  }

  :focus-visible::after {
    content: "";
    position: absolute;
    top: 0;
    left: -${(p) => p.theme.space[1]}px;
    right: -${(p) => p.theme.space[1]}px;
    bottom: 0;
    outline: 2px solid var(--focus-color);
    z-index: 3;
    border-radius: ${(p) => p.theme.radii.md};
  }
`;

const StyledStack = styled(Stack)`
  --divider-color: ${(p) => p.theme.colors.N200};

  position: relative;
  border-bottom: 1px solid var(--divider-color);
`;

function LeftArrowHeader(props: HeaderProps) {
  const { open, setOpen, title } = props;
  return (
    <StyledStack direction={"horizontal"} gap={1} py={1}>
      <StyledIcon
        icon={
          <motion.div variants={{ open: { rotate: 90 }, close: { rotate: 0 } }}>
            <CaretRight size={16} weight="bold" />
          </motion.div>
        }
        onClick={() => setOpen(!open)}
        color="N600"
        marginTop={"1/2"}
      />

      <Box width={"100%"}>{title}</Box>
    </StyledStack>
  );
}

function BaseCollapsible(props: CollapsibleProps) {
  const { title, active, header, children, ...rest } = props;
  const [open, setOpen] = useState(!!active);

  useEffect(() => {
    if (open !== active) {
      setOpen(active);
    }
  }, [active]);

  const headerProps: HeaderProps = {
    open,
    setOpen,
    title,
  };

  return (
    <>
      <motion.div animate={open ? "open" : "close"}>
        {header?.(headerProps) ?? <LeftArrowHeader {...headerProps} />}
        <Box {...rest}>
          <motion.div
            initial={{
              height: open ? "auto" : 0,
              overflow: open ? "unset" : "hidden",
            }}
            variants={{
              open: {
                height: "auto",
                visibility: "initial",
                transitionEnd: {
                  overflow: "unset",
                },
              },
              close: {
                height: 0,
                overflow: "hidden",
                transitionEnd: {
                  visibility: "hidden",
                },
              },
            }}
            transition={{ duration: 0.15 }}
          >
            {children}
          </motion.div>
        </Box>
      </motion.div>
    </>
  );
}

export function Collapsible(props: CollapsibleProps) {
  const { children, ...rest } = props;
  return (
    <BaseCollapsible
      {...rest}
      header={(headerProps) => <LeftArrowHeader {...headerProps} />}
    >
      {children}
    </BaseCollapsible>
  );
}
