import { useCallback, useContext } from "react";
import { ReCaptchaContext } from "~context/RecaptchaContext";
import { sdk, SDKGeneratorFn, SDKMethod, SDKOptions } from "~lib";

export const useReCaptchaSDK = () => {
  const { executeRecaptcha } = useContext(ReCaptchaContext);

  const withReCaptchaVerify = useCallback(
    async <T>(
      action: string,
      callback?: (recaptchaToken?: string) => T | Promise<T>
    ): Promise<T> => {
      if (!executeRecaptcha) {
        console.warn("useReCaptcha: Execute recaptcha not yet available");
        void callback?.(undefined);
        return;
      }

      const recaptchaToken = await executeRecaptcha(action);
      try {
        return await callback?.(recaptchaToken);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [executeRecaptcha]
  );

  /**
   * recatpchaSDKGenerator will return a function that will return a sdk
   * object with the given options as well as a computed recaptcha token
   * set on the sdk client. NOTE: this is really only designed to be used with one-off
   * mutations. Avoid using with SWR.
   *
   * @param options - the options provided to the underlying call to sdk()
   * @param method - the graphql operation that will be executed. It is used as
   * the recaptcha action value to track API usage.
   */
  const recaptchaSdkGenerator = useCallback<SDKGeneratorFn>(
    async (method?: SDKMethod) =>
      await withReCaptchaVerify(
        method ?? "unknown_action",
        (recaptchaToken) => (options: Omit<SDKOptions, "recaptchaToken">) =>
          sdk({ ...options, recaptchaToken })
      ),
    [withReCaptchaVerify]
  );

  return {
    recaptchaSdkGenerator,
  };
};
