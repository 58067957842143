import { ExtendedFile } from "~graphql/sdk";
import { FormValues, ObjectWithStringValues } from "./schema.types";
import { isValid, parseISO } from "date-fns";
import * as yup from "yup";
import { MixedSchema } from "yup/lib/mixed";
import { EventWithListedRelease } from "../types";
import { yupResolver } from "@hookform/resolvers";
import {
  validateDateRanges,
  validateTicketSaleDates,
} from "./general-settings/general.validation";
import { set } from "lodash";
import { FieldError } from "react-hook-form";
import { validateRichText } from "@flicket/utils";

yup.addMethod(
  yup.mixed,
  "whenPathFalse",
  function format(path: string[], value: unknown = null) {
    return this.when(path, {
      is: (...args) => {
        return args.every((v) => !v);
      },
      then: (schema: MixedSchema) => schema.transform(() => value),
    });
  }
);

export function oneOfEnum<T>(enumObject: { [s: string]: T } | ArrayLike<T>) {
  return yup.mixed<T>().oneOf(Object.values(enumObject));
}

export function transformEventIntoFormValues(
  data?: Partial<EventWithListedRelease>
) {
  if (!data) return;
  return {
    ...data,
    gateways: data?.gateways
      ?.map((item) => (typeof item === "string" ? item : item.id))
      .concat(data?.canUsePoints ? ["points"] : []),
    tags: data?.tags?.map(({ id }) => id),
  };
}

export type TransformedEventValues = ReturnType<
  typeof transformEventIntoFormValues
>;

/**
 * Helper to replace values of an object with a string path to the nested key.
 */
export function replaceValuesWithPaths<T>(
  obj: T,
  path = ""
): ObjectWithStringValues<T> {
  const result: Record<string, unknown> = {};

  for (const key in obj) {
    const newPath = path ? `${path}.${key}` : key;
    const value = obj[key];
    if (
      typeof value === "object" &&
      value !== null &&
      // field arrays, only want the top-level form name
      Array.isArray(value) === false &&
      // File uploads are an exception to the rule and
      // require a path to the file object, not the nested properties
      !((value as unknown) as ExtendedFile)?.storage
    ) {
      result[key] = replaceValuesWithPaths(value, newPath);
    } else {
      result[key] = newPath;
    }
  }

  return result as ObjectWithStringValues<T>;
}

export const dateOrNull = (value: Date) => {
  return isValid(value) ? new Date(value) : null;
};

export const dateStringOrNull = (value: string | Date): string => {
  const date = parseDate(value);
  return date ? date.toISOString() : null;
};

export function parseDate(value: any): Date | undefined {
  if (!value) return;
  const maybeDate = (typeof value === "string"
    ? parseISO(value)
    : value) as Date;
  return isValid(maybeDate) ? maybeDate : undefined;
}

export function isValidDate(value: any): boolean {
  const maybeDate = parseDate(value);
  return isValid(maybeDate);
}

export function richTextSchema(defaultValue?: string): yup.StringSchema {
  return yup
    .string()
    .trim()
    .nullable()
    .transform(richTextOrNull)
    .default(defaultValue ?? "");
}

export function arraySchema<T>(defaultValue?: T[]) {
  return yup
    .array()
    .nullable()
    .default(defaultValue ?? []);
}

export function richTextOrNull(value: string) {
  return validateRichText(value) ? value : null;
}

export function numberOrNull(value: number) {
  // Using global `isFinite` because it tries to cast the value to a number
  // and numbers from the form can come in as strings. However, `isFinite`
  // returns true for `null` (whereas Number.isFinite returns false) and then
  // Number(null) coerces to 0. So we need to check for `null` first.
  if (value === null) return null;
  return isFinite(value) ? Number(value) : null;
}

export function dateStringSchema(defaultValue?: string) {
  return yup
    .string()
    .nullable()
    .transform(dateStringOrNull)
    .default(defaultValue ?? null);
}

export function numberOrNullSchema(value?: number) {
  return yup.number().transform(numberOrNull).default(value);
}

export function fileSchema(defaultValue?: ExtendedFile) {
  return yup
    .mixed()
    .nullable()
    .transform(imageOrNull)
    .default(defaultValue ?? null);
}

export const imageOrNull = (image: File | Record<string, any>) => {
  return image ? image : null;
};

export function toDisplayPrice(price: number | null | undefined): string {
  return typeof price === "number" ? price.toFixed(2) : price;
}

export const validationResolver = (schema: yup.ObjectSchema<any>) => async (
  values: FormValues,
  context: object
) => {
  const { errors, values: resultValues } = await yupResolver(schema)(
    values,
    context
  );

  const ticketSaleErrors = validateTicketSaleDates(
    values.listedReleaseOptions,
    values.dates
  );

  const eventDateErrors = validateDateRanges(values.dates);

  ticketSaleErrors
    .concat(eventDateErrors)
    .forEach(({ name, message, type }) => {
      set(errors, name, { message, type } as FieldError);
    });

  console.error(eventDateErrors, errors);

  return {
    values: resultValues,
    errors,
  };
};

export const imageGallerySchema = yup.array().of(fileSchema()).nullable();
