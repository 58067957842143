import { SummaryElement, SummaryType } from "./interface/slate.interface";
import { EmptyParagraph } from "./util";
import { CaretDown, CaretUp, LockSimple } from "@phosphor-icons/react";
import { Stack } from "flicket-ui";
import { ReactNode } from "react";
import { Transforms, Range, Editor, NodeEntry, Node, Path } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor, RenderElementProps, useSlate } from "slate-react";
import { useTheme } from "styled-components";
import { Icon } from "../Icon";
import { WhiteButton } from "../WhiteButton";
import { usePopover } from "./Popover/usePopover";

const summaryLabelMap: Record<SummaryType, string> = {
  "order-details": "Order details",
  "auto-renewal-details": "Auto-renewal details",
  "ticket-details": "Event details",
  "competition-prize-details": "Competition prize details",
  "ticket-delay": "Ticket delay details",
};

export const insertSummary = (editor: Editor, summaryName: SummaryType) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };
  const summaryElement: SummaryElement = {
    type: "summary",
    content: summaryName,
    children: [{ text: "" }],
  };

  Transforms.insertNodes(editor, summaryElement, { at: blurSelection });
  Transforms.insertNodes(editor, EmptyParagraph, { at: newRange });
};

export const withSummary = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "summary" ? true : isVoid(element);
  };

  return editor;
};

type SummaryProps = Pick<RenderElementProps, "attributes"> & {
  children: ReactNode;
  element: SummaryElement;
};

const up = (editor: Editor, nodeEntry: NodeEntry<Node>) => {
  const [, path] = nodeEntry;

  // have to check if the path is not the first element since Path.previous(path) will throw an error if the path is [0]
  if (path[path.length - 1] !== 0) {
    const previousPath = Path.previous(path);

    Transforms.moveNodes(editor, {
      at: path,
      to: previousPath,
    });
  }
};

const down = (editor: Editor, nodeEntry: NodeEntry<Node>) => {
  const [, path] = nodeEntry;

  const nextPath = Path.next(path);

  Transforms.moveNodes(editor, {
    at: path,
    to: nextPath,
  });
};

export const Summary = (props: SummaryProps) => {
  const { attributes, element, children } = props;
  const theme = useTheme();
  const editor = useSlate();
  const { isPopoverOpen, popoverRef, selectedNode } = usePopover("summary");
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme.colors.N100,
        textAlign: "center",
        padding: `${theme.space[4]}px 0`,
        margin: `${theme.space[4]}px 0`,
        position: "relative",
        boxShadow: isPopoverOpen ? `0 0 0 2px ${theme.colors.P300}` : "none",
        borderRadius: theme.radii.md,
      }}
      contentEditable={false}
      {...attributes}
    >
      <Stack direction={"vertical"} gap={1} alignItems="center">
        {element.isRemovable === false ? (
          <Stack
            backgroundColor={"N200"}
            width="40px"
            height="40px"
            alignItems="center"
            justifyContent={"center"}
            borderRadius={theme.radii.full as any}
          >
            <Icon icon={<LockSimple />} />
          </Stack>
        ) : null}
        {summaryLabelMap[element.content]}
      </Stack>
      {children}
      {isPopoverOpen && (
        <Stack
          gap={"1/2"}
          ref={popoverRef}
          direction="vertical"
          position={"absolute"}
          top={1}
          left={1}
        >
          <WhiteButton
            {...props}
            height={"16px"}
            width={"16px"}
            justifyContent={"center"}
            padding={2}
            onClick={() => up(editor, selectedNode)}
          >
            <Icon icon={<CaretUp weight="bold" />} />
          </WhiteButton>
          <WhiteButton
            {...props}
            height={"16px"}
            width={"16px"}
            justifyContent={"center"}
            padding={2}
            onClick={() => down(editor, selectedNode)}
          >
            <Icon icon={<CaretDown weight="bold" />} />
          </WhiteButton>
        </Stack>
      )}
    </div>
  );
};
