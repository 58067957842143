import { Stack } from "flicket-ui";
import { ReactNode } from "react";
import { RenderElementProps } from "slate-react";
import { BoxElement } from "./interface/slate.interface";

type BoxProps = Pick<RenderElementProps, "attributes"> & {
  children: ReactNode;
  element: BoxElement;
};

export default function SlateBox(props: BoxProps) {
  const { children } = props;

  return (
    <Stack position={"relative"} direction={"horizontal"} {...props.attributes}>
      {children}
    </Stack>
  );
}
