import { ChangeEvent, FC, Fragment, useMemo, useState } from "react";
import {
  Box,
  PrimaryButton,
  Text,
  Flex,
  TransparentButton,
  Checkbox,
} from "flicket-ui";

import { Divider, ModalBase, Icon } from "~components";
import { useOrganization } from "~hooks";
import { showToast } from "~lib";
import { LineItem } from "~components/orders";
import { LineItemType, OrderQuery, OrderStatus } from "~graphql/sdk";
import { Printer } from "@flicket/utils";
import { apiUrl } from "~config";

const printer = new Printer(apiUrl);

type Ticket = OrderQuery["order"]["tickets"][number];
interface PrintModalProps {
  isOpen: boolean;
  close: () => void;
  order?: OrderQuery["order"];
  tickets?: OrderQuery["order"]["tickets"];
  enableReceipts?: boolean;
}

export const SelectablePrintModal: FC<PrintModalProps> = ({
  isOpen,
  close,
  order,
  tickets: activeTickets,
  enableReceipts = false,
}) => {
  const { organization } = useOrganization();
  const [printableTicketList, setPrintableTicketList] = useState<Ticket[]>([]);
  const [showPrintableTickets, setShowPrintableTickets] = useState(false);
  const [markAsPreprint, setMarkAsPreprint] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const tickets = useMemo(
    () =>
      activeTickets?.filter(
        ({ lineItem: { type } }) =>
          type === LineItemType.Ticket || type === LineItemType.Membership
      ),
    [activeTickets]
  );

  const addOns = useMemo(
    () =>
      activeTickets?.filter(
        ({ lineItem: { type } }) => type === LineItemType.Addon
      ),
    [activeTickets]
  );

  const onPrint = async () => {
    if (!printableTicketList.length) {
      return;
    }

    try {
      setIsPrinting(true);
      showToast("Print job started.");
      await printer.printHardcopyTickets({
        organizationId: organization.id,
        orderId: order.id,
        markAsPreprint,
        isMembership: !!order.membership,
        receipt: enableReceipts,
        ticketIds: printableTicketList.map((ticket) => ticket.id),
      });
    } catch (error) {
      showToast(
        error instanceof Error ? error.message : String(error),
        "error"
      );
    }

    setIsPrinting(false);
  };

  const togglePrintAllList = (addAll: boolean) => {
    setPrintableTicketList(addAll ? tickets : []);
  };

  const togglePrintableTicket = (currentTicket: Ticket, addTicket: boolean) => {
    let newTickets: Ticket[] = [];

    newTickets = tickets?.filter(
      (ticket: Ticket) =>
        (addTicket && ticket.id === currentTicket.id) ||
        (ticket.id !== currentTicket.id &&
          printableTicketList.some((item) => item.id === ticket.id))
    );

    setPrintableTicketList(newTickets);
  };

  return (
    <ModalBase isOpen={isOpen} close={close}>
      <Text variant="extraBold.L" color="N800">
        {"Print tickets"}
      </Text>
      <Divider mt={2} mb={3} />
      {tickets?.length && (
        <Flex flex={1} justifyContent="space-between">
          <Checkbox
            label="Print all tickets"
            checked={printableTicketList?.length === tickets?.length}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              togglePrintAllList(e.target.checked)
            }
          />
          <TransparentButton
            onClick={() => setShowPrintableTickets(!showPrintableTickets)}
            flex={1}
            justifyContent="flex-end"
          >
            {printableTicketList?.length > 0 && (
              <Text fontSize={2} fontWeight="heavy">
                ({printableTicketList.length} ticket
                {printableTicketList.length > 1 && "s"} selected)
              </Text>
            )}
            <Icon
              icon="chevron-down"
              ml="1/4"
              css={`
                transform: rotate(${showPrintableTickets ? 180 : 0}deg);
                transition: transform ease-in-out 0.3s;
              `}
            />
          </TransparentButton>
        </Flex>
      )}

      {order?.status === OrderStatus.Hold && (
        <>
          <Divider mt={2} mb={3} />

          <Checkbox
            label="Mark order as preprint"
            checked={markAsPreprint}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setMarkAsPreprint(e.target.checked)
            }
          />
          <Text color="N800" mb={2}>
            {
              "Please note that preprinted tickets can be scanned in but won't count towards the total number of tickets sold or revenue until the order is completed."
            }
          </Text>
        </>
      )}

      <Divider mt={2} mb={3} />

      {showPrintableTickets && (
        <Box mt={4}>
          {tickets?.length > 0 && (
            <Box as="ul">
              {tickets?.map((ticket) => (
                <Flex flex={1} justifyContent="space-between" key={ticket.id}>
                  <Checkbox
                    label={`${ticket?.lineItem.name} | (#${ticket?.ticketNumber})`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      togglePrintableTicket(ticket, e.target.checked)
                    }
                    cursor="pointer"
                    checked={Boolean(
                      printableTicketList.length > 0 &&
                        printableTicketList?.find(
                          (printTicket: Ticket) => printTicket.id === ticket.id
                        )
                    )}
                  />
                </Flex>
              ))}

              <Divider mt={2} mb={3} />
            </Box>
          )}

          {addOns?.length > 0 && (
            <Box as="ul">
              {addOns?.map((item) => (
                <Fragment key={item.id}>
                  <LineItem
                    {...item}
                    fullName={order?.user?.fullName}
                    key={item?.id}
                  />
                </Fragment>
              ))}
            </Box>
          )}
        </Box>
      )}

      <Flex justifyContent="flex-end" alignItems="center" mt={4}>
        <PrimaryButton
          fontSize={2}
          onClick={() => {
            void onPrint();
          }}
          disabled={!printableTicketList.length || isPrinting}
        >
          {isPrinting ? "Printing..." : "Print tickets"}
        </PrimaryButton>
      </Flex>
    </ModalBase>
  );
};
