import {
  DATE_FORMAT,
  DAY_MONTH_FORMAT,
  earliestStartDate,
  formatToString,
} from "~lib/helpers/dates/formatDateRange";
import { SearchEvent, SearchMembership, SearchSeason } from "./interfaces";
import { isSameDay, isSameMonth, isSameYear } from "date-fns";
import { PRIMARY_NAVIGATION_KEYS } from "../../navigation/primary.config";

export type EventsGroup = {
  label: string;
  options: SearchEvent[];
};

export type MembershipsGroup = {
  label: string;
  options: SearchMembership[];
};

export type SeasonsGroup = {
  label: string;
  options: SearchSeason[];
};

export type ReportingGroup = EventsGroup | MembershipsGroup | SeasonsGroup;

export const allEvents = {
  type: PRIMARY_NAVIGATION_KEYS.EVENTS,
  label: "All events",
  id: "all-events",
} as const;

export const allMemberships = {
  type: PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS,
  label: "All memberships",
  id: "all-memberships",
} as const;

export const allSeasons = {
  type: PRIMARY_NAVIGATION_KEYS.SEASONS,
  label: "All seasons",
  id: "all-seasons",
} as const;

type AllEvents = typeof allEvents;
type AllMemberships = typeof allMemberships;
type AllSeasons = typeof allSeasons;

export type EventOptionType = SearchEvent | AllEvents;
export type MembershipOptionType = SearchMembership | AllMemberships;
export type SeasonOptionType = SearchSeason | AllSeasons;
export type ReportingOptionType =
  | EventOptionType
  | MembershipOptionType
  | SeasonOptionType;

export function isAllEvents(option: EventOptionType): option is AllEvents {
  return option?.id === "all-events";
}

export function isAllMemberships(
  option: MembershipOptionType
): option is AllMemberships {
  return option?.id === "all-memberships";
}

export function isAllSeasons(option: SeasonOptionType): option is AllSeasons {
  return option?.id === "all-seasons";
}

export function groupEvents(events: SearchEvent[]) {
  const groups: EventsGroup[] = [];
  const groupsMap: { [key: string]: EventsGroup } = {};

  for (const event of events) {
    const year = new Date(earliestStartDate(event.dates)).getFullYear();

    if (groupsMap[year]) {
      groupsMap[year].options.push(event);
      continue;
    }

    const newGroup = {
      label: year.toString(),
      options: [event],
    };
    groupsMap[year] = newGroup;
    groups.push(newGroup);
  }

  return groups;
}

export function groupMemberships(memberships: SearchMembership[]) {
  const groups: MembershipsGroup[] = [];
  const groupsMap: { [key: string]: MembershipsGroup } = {};

  for (const membership of memberships) {
    const year = new Date(membership.startDate).getFullYear();

    if (groupsMap[year]) {
      groupsMap[year].options.push(membership);
      continue;
    }

    const newGroup = {
      label: year.toString(),
      options: [membership],
    };
    groupsMap[year] = newGroup;
    groups.push(newGroup);
  }

  return groups;
}

export function groupSeasons(seasons: SearchSeason[]) {
  const groups: SeasonsGroup[] = [];
  const groupsMap: { [key: string]: SeasonsGroup } = {};

  for (const season of seasons) {
    const year = new Date(season.startDate).getFullYear();

    if (groupsMap[year]) {
      groupsMap[year].options.push(season);
      continue;
    }

    const newGroup = {
      label: year.toString(),
      options: [season],
    };
    groupsMap[year] = newGroup;
    groups.push(newGroup);
  }

  return groups;
}

export default function formatDateRangeDayOnly({
  start,
  end,
}: {
  start?: Date | string;
  end?: Date | string;
}) {
  if (!start && !end) {
    throw new Error("Must provide at least one date to `formatDateRange`");
  }

  if (!end) {
    return formatToString(start, DATE_FORMAT);
  }

  if (!start) {
    return formatToString(end, DATE_FORMAT);
  }

  if (isSameDay(new Date(start), new Date(end))) {
    return formatToString(start, DATE_FORMAT);
  }

  // 1 - 2 April
  if (
    isSameMonth(new Date(start), new Date(end)) &&
    isSameYear(new Date(start), new Date(end))
  ) {
    return (
      formatToString(start, "d") + " - " + formatToString(end, DATE_FORMAT)
    );
  }

  // 1 April - 2 Feb 2024
  if (isSameYear(new Date(start), new Date(end))) {
    return (
      formatToString(start, DAY_MONTH_FORMAT) +
      " - " +
      formatToString(end, DATE_FORMAT)
    );
  }

  // 2 April 2023 - 1 Feb 2024
  return (
    formatToString(start, DATE_FORMAT) +
    " - " +
    formatToString(end, DATE_FORMAT)
  );
}
