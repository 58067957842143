import { Box, PrimaryButton, Stack, TertiaryButton } from "flicket-ui";
import Modal from "~components/common/CustomModal/CustomModal";
import { FormEvent, useEffect, useState } from "react";
import { useQuery } from "~hooks/useQuery";
import { GetShopifyProductsDocument } from "~graphql/typed-document-nodes";
import {
  MerchandiseItemSelect,
  SelectOption,
} from "~components/common/RichText/InsertMerchandiseModal/MerchandiseItemSelect";
import useEmailDataContext from "~features/emailCustomisation/hooks/useEmailDataContext";
import { MerchandiseElement } from "~components/common/RichText/interface/slate.interface";

export interface InsertMerchandiseFormProps {
  close: () => void;
  onSubmit: (values: SelectOption[]) => Promise<void> | void;
  afterSubmit?: () => void;
  merchandise: MerchandiseElement;
  onUpdate: (values: SelectOption[]) => Promise<void> | void;
  eventId?: string;
  membershipId?: string;
}

export default function InsertMerchandiseForm(
  props: InsertMerchandiseFormProps
) {
  const { close, onSubmit, onUpdate } = props;

  const [currentValue, setCurrentValue] = useState<SelectOption[]>(
    props.merchandise?.items ?? []
  );

  const context = useEmailDataContext();

  let products = [];
  if (context) {
    const input =
      context.type === "event"
        ? { eventId: context.id }
        : { membershipId: context.id };
    const { data: shopifyProducts, mutate: mutateProducts } = useQuery(
      GetShopifyProductsDocument,
      input
    );
    products = shopifyProducts?.getShopifyProducts?.products;
  }

  useEffect(() => {
    if (context && !props.merchandise) {
      const selectOptions: SelectOption[] = products?.map((product) => ({
        value: product.id,
        label: product.name,
        image: product.imageUrl,
        price: parseFloat(product.price),
        url: product.url,
      }));
      setCurrentValue(selectOptions);
    }
  }, [products, context, props.merchandise]);

  const _onSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.merchandise?.items ? onUpdate(currentValue) : onSubmit(currentValue);
    return;
  };

  return (
    <>
      <Modal.Header>Insert merchandise links</Modal.Header>
      <Modal.Content overflowY={"visible"}>
        <Box>
          <MerchandiseItemSelect
            currentValue={currentValue}
            setCurrentValue={setCurrentValue}
            products={products}
          />
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Stack gap={1}>
          <TertiaryButton onClick={close}>Cancel</TertiaryButton>
          <PrimaryButton type="submit" onClick={_onSubmit}>
            {props.merchandise?.items ? `Update` : `Insert`}
          </PrimaryButton>
        </Stack>
      </Modal.Footer>
    </>
  );
}
