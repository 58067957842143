import { Stack } from "flicket-ui";
import { FC, useState } from "react";
import { Editor, Transforms } from "slate";
import { ReactEditor, RenderElementProps, useSlate } from "slate-react";
import { Modal } from "~components";
import { useOrganization } from "~hooks";
import { addProtocolToURL } from "~lib/helpers/addProtocolToURL";
import { textColourFromLuminance } from "~lib/helpers/colours";
import { InsertModalContent } from "./InsertModal";
import { ButtonElement, SuggestedLinkType } from "./interface/slate.interface";
import { LinkPopover, StyledPopover } from "./Popover/LinkPopover";
import { usePopover } from "./Popover/usePopover";

export const withButton = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "button" ? true : isVoid(element);
  };

  return editor;
};

export const insertButton = (
  editor: Editor,
  content: string,
  url?: string,
  suggestedLink?: SuggestedLinkType,
  eventId?: string,
  releaseId?: string,
  membershipId?: string
) => {
  let blurSelection = editor.blurSelection;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  url = addProtocolToURL(url);

  const button: ButtonElement = {
    type: "button",
    url,
    content,
    suggestedLink,
    eventId,
    releaseId,
    membershipId,
    children: [{ text: "" }],
  };

  Transforms.insertNodes(editor, button, {
    at: blurSelection,
  });
  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

const editButton = (
  editor: Editor,
  content: string,
  url?: string,
  suggestedLink?: SuggestedLinkType,
  eventId?: string,
  releaseId?: string,
  membershipId?: string
) => {
  const blurSelection = editor.blurSelection;
  if (!blurSelection) {
    console.error("Cannot edit button without selection.");
    return;
  }

  url = addProtocolToURL(url);

  Transforms.setNodes(
    editor,
    {
      url,
      content,
      suggestedLink,
      eventId,
      releaseId,
      membershipId,
    },
    {
      at: blurSelection,
    }
  );

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export const RichTextInsertButton: FC<
  Pick<RenderElementProps, "attributes"> & {
    element: ButtonElement;
    color: string;
  }
> = ({ attributes, children, color, element }) => {
  const {
    content,
    suggestedLink,
    eventId,
    releaseId,
    membershipId,
    variant,
    align,
  } = element;
  let { url } = element;
  if (suggestedLink) {
    url = undefined;
  }
  const { organization } = useOrganization();
  const primaryColor = organization?.branding?.primaryColor;
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const { isPopoverOpen, popoverRef, selectedNode } = usePopover("button");

  let backgroundColor = color;
  let border = "none";
  if (variant === "secondary") {
    backgroundColor = "#ffffff";
    border = "1px solid #cccccc";
  }

  let justifyContent = "center";
  if (align === "left") {
    justifyContent = "flex-start";
  } else if (align === "right") {
    justifyContent = "flex-end";
  }

  //  Border based button https://litmus.com/blog/a-guide-to-bulletproof-buttons-in-email-design
  return (
    // added contentEditable={false} and userSelect="none" to prevent the "Cannot resolve a Slate point from DOM point" error
    <div contentEditable={false} style={{ userSelect: "none" }}>
      <Stack position={"relative"} justifyContent={justifyContent}>
        <a
          {...attributes}
          css={`
            color: ${backgroundColor
              ? textColourFromLuminance(backgroundColor)
              : primaryColor} !important;
            font-weight: bold;
            border-radius: 3px;
            background-color: ${backgroundColor};
            padding: 12px 18px;
            border: ${border};
            display: inline-block;
            text-decoration: none;
          `}
        >
          {children}
          {content}
        </a>
        {isPopoverOpen && (
          <StyledPopover ref={popoverRef} bottom={"calc(100% + 8px)"}>
            <LinkPopover
              editor={editor}
              url={url}
              setIsOpen={setIsOpen}
              displayRemoveButton={
                selectedNode ? selectedNode[0]?.isRemovable !== false : true
              }
            />
          </StyledPopover>
        )}
      </Stack>

      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        <InsertModalContent
          setIsOpen={setIsOpen}
          isEmail
          isEdit
          defaultValues={{
            event: { id: eventId, name: "" },
            url,
            urlDisplayText: content,
            suggestedLink,
            release: { id: releaseId, name: "" },
            membership: { id: membershipId, name: "" },
            type: "button",
          }}
          onSuccess={(options) => {
            const {
              urlDisplayText,
              url,
              suggestedLink,
              event,
              release,
              membership,
            } = options;
            editButton(
              editor,
              urlDisplayText,
              url,
              suggestedLink,
              event?.id,
              release?.id,
              membership?.id
            );
          }}
        />
      </Modal>
    </div>
  );
};
