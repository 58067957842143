import { Flex, system, SystemProps } from "flicket-ui";
import styled from "styled-components";
import { Icon } from "~components";
import { Button } from "../components";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { Editor } from "slate";
import { useAtom } from "jotai";
import { merchandiseModalState } from "~components/common/RichText/InsertMerchandiseModal";
import { MerchandiseElement } from "~components/common/RichText/interface/slate.interface";

export const StyledPopover = styled(Flex)<SystemProps>`
  position: absolute;
  z-index: 1000;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  padding: 8px 12px;
  align-items: center;
  font-size: 14px;

  ${system}
`;

// Popover for buttons and links
export const MerchandisePopover = ({
  editor,
  displayRemoveButton = true,
  merchandise,
}: {
  merchandise: MerchandiseElement;
  editor: Editor;
  displayRemoveButton?: boolean;
}) => {
  const [, setMerchandiseModalState] = useAtom(merchandiseModalState);
  return (
    <>
      <Button
        w="30px"
        h="30px"
        onClick={() => {
          setMerchandiseModalState({
            isOpen: true,
            merchandise: merchandise,
          });
        }}
      >
        <Icon icon={<PencilSimple size={18} />} />
      </Button>

      {displayRemoveButton && (
        <Button
          w="30px"
          h="30px"
          onClick={() => {
            // there is a space character before the merchandise element
            editor.deleteBackward("character");
          }}
        >
          <Icon icon={<Trash size={18} />} />
        </Button>
      )}
    </>
  );
};
