import { Box, Input, Text } from "flicket-ui";
import { ChangeEvent, useState } from "react";
import { Divider } from "~components";
import { EventMembershipDropDown } from "~components/common/RichText/EventMembershipDropDown";
import {
  getHeaderText,
  InsertActionButtons,
} from "~components/common/RichText/InsertModal";
import {
  InsertLinkModalContentState,
  InsertModalContentProps,
} from "./InsertModal.types";

export const InsertSMSModalContent = ({
  setIsOpen,
  isEdit,
  defaultValues,
  onSuccess,
}: InsertModalContentProps) => {
  const [modalState, setModalState] = useState<InsertLinkModalContentState>(
    defaultValues
  );
  const suggestedLink = defaultValues?.suggestedLink;

  const headerText = getHeaderText({
    type: "link",
    isEdit,
    suggestedLink,
  });

  return (
    <>
      <Box d="flex" alignItems="center">
        <Text fontWeight="heavy" fontSize={6}>
          {headerText}
        </Text>
      </Box>
      <Divider />

      {suggestedLink && (
        <EventMembershipDropDown
          onChange={setModalState}
          selected={modalState}
        />
      )}

      {!suggestedLink && (
        <Input
          mt={3}
          label={"URL"}
          value={modalState.url}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setModalState({
              url: e.target.value,
              urlDisplayText: e.target.value,
            });
          }}
        />
      )}
      <Text mt={4} mb={4} fontSize={"14px" as any}>
        Link will be automatically shortened to 22 characters.
      </Text>

      <InsertActionButtons
        setIsOpen={setIsOpen}
        onSuccess={onSuccess}
        type={"link"}
        shouldLoadEvents={false}
        state={modalState}
      />
    </>
  );
};
